import React, { useRef, useState, useEffect } from "react";
import { createCompletion } from "../services/openai.service";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "@emotion/styled";
import NoMessages from "../images/nomessages.webp";
import { Button, Input, Link, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Chip from "@mui/material/Chip";
import jwt_decode from "jwt-decode";
import TemporaryDrawer from "./sidebar";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import ChatMessagenger from "./chatMessage";
import { setState, getState } from "../util/localStorage";
import { prompts } from "../util/prompts";
import ApiRequestService from "../services/api.service";
const ParentBox = styled.div`
	width: 100%;
`;
const ChatInputBox = styled.div`
	position: fixed;
	z-index: 1000;
	bottom: 3em;
	width: 100%;
`;
const MessagesBox = styled.div`
	padding-bottom: 13em;
	overflow-y: auto;
	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	/* Track style */
	::-webkit-scrollbar-track {
		background-color: #f5f5f5;
		border-radius: 3px;
	}

	/* Thumb style */
	::-webkit-scrollbar-thumb {
		border-radius: 3px;
		&:hover {
			background-color: #999;
		}
	}
`;
const ButtonWrapper = styled.div`
	margin: auto 0;
	display: flex;
	align-items: center;
	gap: 1em;
	padding: 1em var(--padding);
	/* border: 1px solid pink; */
`;

function LandingPage() {
	const ApiCall = new ApiRequestService();
	const [activeMessageTobeSent, setActiveMessagetoBeSent] = useState("");
	const [numberOfTimeUsed, setNumberOfTimeUsed] = useState(0);
	const [loading, setLoading] = useState(false);
	const [isUserSignedUp, setIsUserSignedUp] = useState(false);
	const [serverError, setServerError] = useState(false);
	const [chatArr, setChatArr] = useState([
		{
			text: "hey whats up?",
			direction: "flex-end",
			user: "Hey loveGPT what should i reply to this: (example)",
			example: true,
		},
		{
			text: "Talking with someone cute 😉",
			direction: "flex-start",
			user: "LoveGPT: Sure, you can reply something like this below: (example)",
			example: true,
		},
	]);
	const [inputVal, setInputVal] = useState("");
	const [onSuccessBool, setonSuccessBool] = useState(false);
	const SideBarRef = useRef(null);
	const MessageBoxRef = useRef("");
	const LoveGPTstyles = {
		color: "white",
	};
	const UserStyles = {
		background: "white",
		color: "black",
	};

	useEffect(() => {
		// console.log(numberOfTimeUsed, isUserSignedUp);
		if (numberOfTimeUsed > 0 && !isUserSignedUp) {
			setonSuccessBool(true);
		}
	}, [numberOfTimeUsed]);

	useEffect(() => {
		let clickNumber = getState("numberOfTimesUsed");
		let userDetails = getState("userDetails");
		// console.log(userDetails);
		if (userDetails) {
			setIsUserSignedUp(true);
		}
		if (!clickNumber) {
			setState("numberOfTimesUsed", 0);
			setNumberOfTimeUsed(0);
		} else {
			setNumberOfTimeUsed(parseInt(clickNumber));
		}
	}, [onSuccessBool]);

	const sendMessage = async (message = "", isFromChat = false) => {
		window.setTimeout(() => {
			const offsetBottom =
				MessageBoxRef.current?.offsetTop +
				MessageBoxRef.current?.offsetHeight;
			window.scrollTo({ top: offsetBottom });
		}, 1000);
		// window.setTimeout(() => {
		// 	MessageBoxRef.current.scrollIntoView({ behavior: "smooth" });
		// }, 1000);
		try {
			setNumberOfTimeUsed((prev) => prev + 1);
			setState("numberOfTimesUsed", numberOfTimeUsed + 1);
			setChatArr((prev) => [
				...prev,
				{
					text: message,
					direction: "flex-end",
					user: isFromChat
						? "Hey loveGPT what should i reply to this :"
						: "Hey loveGPT help me to :",
				},
			]);

			setLoading(true);

			let textToBeSent = [
				"oh",
				"hmm",
				"ok",
				"okay",
				"mm",
				"umm",
				"erm",
				"umm",
				"uh-huh",
				"i dont know",
				"like",
				"i guess",
				"maybe",
				"i think",
				"you know",
				"so",
			].includes(message.toLowerCase())
				? `i was chatting with my love and my love said ${message.toLowerCase()}, what should i say in reply?`
				: message;
			const userData = getState("userDetails");
			const response = await ApiCall.postApi(
				`${process.env.REACT_APP_SERVER_URL}/user/createCompletion`,
				{ "Content-Type": "application/json" },
				{
					...(isFromChat && { prompt: textToBeSent }),
					...(!isFromChat && { title: textToBeSent }),
					...(userData?.email && { email: userData?.email }),
				}
			);

			setActiveMessagetoBeSent({
				title: textToBeSent,
				isFromChat: isFromChat,
			});
			//   console.log("9989", response.data.data.indexOf("conversation"));

			setChatArr((prev) => [
				...prev,
				{
					text:
						response.data.data.indexOf("conversation") === 1
							? response.data.data.substr(14)
							: response.data.data,
					direction: "flex-start",
					user: [
						"oh",
						"hmm",
						"ok",
						"okay",
						"mm",
						"umm",
						"erm",
						"umm",
						"uh-huh",
						"i dont know",
						"like",
						"i guess",
						"maybe",
						"i think",
						"you know",
						"so",
					].includes(message)
						? "Ahh! we know this pain of short messages"
						: "LoveGPT: Sure, you can reply something like this below :",
				},
			]);
			//   console.log("response", response);
			setInputVal("");
			setLoading(false);
			// MessageBoxRef.current.scrollIntoView({
			//   behavior: "smooth",
			//   block: "nearest"
			// })
			window.setTimeout(() => {
				const offsetBottom =
					MessageBoxRef.current.offsetTop +
					MessageBoxRef.current.offsetHeight;
				window.scrollTo({ top: offsetBottom });
			}, 1000);
		} catch (e) {
			console.log("response", e);

			if (e.code === 500) {
				setServerError(true);
			}

			setLoading(false);
			setChatArr((prev) => [
				...prev,
				{
					// text: e.message || "I know I know we are also excited to see you experiment with us, i talked a lot since an hour, taking a quick nap, do come back in a while okay?!",
					text: "Our servers are temporarily busy, please try again later",
					direction: "flex-start",
					user: "LoveGPT:",
				},
			]);
			window.setTimeout(() => {
				if (MessageBoxRef.current) {
					MessageBoxRef.current.scrollTop =
						MessageBoxRef.current?.scrollHeight;
				}
			}, 1000);
			setInputVal("");
			setLoading(false);
		}
		// console.log('api',apiResponse.data)
	};
	return (
		<>
			<GoogleOAuthProvider clientId="361879430072-4d5o8ouiq24g1gnq08di4r99k7g64ljr.apps.googleusercontent.com">
				{onSuccessBool ? (
					<div
						style={{
							width: "100%",
							height: "100vh",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							textAlign: "center",
						}}
					>
						<Typography
							sx={{ mb: 5, fontWeight: "bold" }}
							varaint="h3"
						>
							{" "}
							Do you like using{" "}
							<span style={{ color: "#ff6396" }}>LoveGPT</span>?
							please signup / login to continue to use it further
						</Typography>
						<Typography sx={{ mb: 5 }} varaint="subtitle2">
							Don't worry we won't spam you, number of sign-ups
							gives us hope that more number of real humans want
							to use this.
						</Typography>
						<GoogleLogin
							onSuccess={async (res) => {
								try {
									let decodedData = jwt_decode(
										res.credential
									);

									setState("userDetails", decodedData);
									setonSuccessBool(false);
									let response = await fetch(
										`${process.env.REACT_APP_SERVER_URL}/user`,
										{
											headers: {
												"Content-Type":
													"application/json",
											},
											method: "POST",
											body: JSON.stringify({
												email: decodedData.email,
												name: decodedData.name,
												picture: decodedData.picture,
											}),
										}
									);
									let responseData = await response.json();
								} catch (e) {
									console.log(e);
								}
							}}
							onError={(res) => {
								setonSuccessBool(false);
							}}
						/>
					</div>
				) : (
					<ParentBox>
						<TemporaryDrawer
							ref={SideBarRef}
							loading={loading}
							sendMessage={sendMessage}
						/>
						{/* <Typography variant="h4" style={{textAlign:"center"}} color="pink">LoveGPT</Typography> */}
						<MessagesBox
							ref={MessageBoxRef}
							style={{ overflowX: "hidden" }}
						>
							{chatArr.length > 0 ? (
								chatArr.map((el) => {
									return (
										<ChatMessagenger
											direction={el.direction}
											message={el.text}
											name={el.user}
											example={el.example}
											activeMessageTobeSent={
												activeMessageTobeSent
											}
											setChatArr={setChatArr}
											chatArr={chatArr}
											sendMessage={sendMessage}
											setLoading={setLoading}
										/>

										// <MessageBox
										//   styles={
										//     el.user === "loveGpt" ? LoveGPTstyles : UserStyles
										//   }
										//   position={el.direction}
										//   title={el.user === "loveGpt" ? "LoveGPT" : "USER"}
										//   type="text"
										//   titleColor={el.user === "loveGpt" && "white"}
										//   text={el.text}
										//   date={new Date()}
										//   replyButton={false}
										// />
									);
								})
							) : (
								<div style={{ textAlign: "center" }}>
									<img src={NoMessages} alt="no-messages" />
								</div>
							)}
						</MessagesBox>
						<ChatInputBox>
							{!serverError ? (
								<>
									<ButtonWrapper>
										<Chip
											onClick={() => {
												if (!loading)
													sendMessage(
														"Start a conversation"
													);
											}}
											variant="contained"
											label="Start conversation"
											sx={{
												background:
													"var(--ternary-clr)",
												color: "white",
												boxShadow: "var(--box-shadow)",
												"&:hover": {
													color: "white",
													background:
														"var(--primary-clr)",
												},
											}}
										/>

										<Chip
											onClick={() => {
												if (!loading)
													sendMessage(
														prompts[3].title
													);
											}}
											variant="contained"
											label="Ask out for coffee"
											sx={{
												background:
													"var(--ternary-clr)",
												color: "white",
												boxShadow: "var(--box-shadow)",
												"&:hover": {
													color: "white",
													background:
														"var(--primary-clr)",
												},
											}}
										/>
										<Chip
											onClick={() => {
												if (!loading)
													SideBarRef.current.log();
											}}
											variant="contained"
											label="Others.."
											sx={{
												background:
													"var(--ternary-clr)",
												color: "white",
												boxShadow: "var(--box-shadow)",
												"&:hover": {
													color: "white",
													background:
														"var(--primary-clr)",
												},
											}}
										/>
									</ButtonWrapper>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											margin: "auto var(--padding)",
											borderRadius:
												"var(--border-radius)",
											background:
												"var(--input-background)",
											padding: "1em",
											boxShadow: "var(--box-shadow)",
											gap: "1em",
										}}
									>
										<input
											fullWidth
											onChange={(e) => {
												setInputVal(e.target.value);
											}}
											readOnly={loading}
											value={inputVal}
											placeholder="write what they said here and I'll tell you how to reply..."
											style={{
												width: "100%",
												border: "none",
												outline: "none",
												background: "none",
												fontSize: "1.25em",
											}}
											onKeyDown={(e) => {
												e.code === "Enter" &&
													sendMessage(inputVal, true);
											}}
										/>
										{loading ? (
											<CircularProgress />
										) : (
											<button
												disabled={!inputVal}
												onClick={() =>
													sendMessage(inputVal, true)
												}
												variant="contained"
												style={{
													background: "none",
													outline: "none",
													border: "none",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													cursor: "disable",
													"&:active": {
														cursor: "pointer",
														color: "black",
													},
												}}
											>
												<SendIcon
													style={{
														transform: "scale(1.2)",
													}}
												/>
											</button>
										)}
									</div>
								</>
							) : (
								<div
									style={{
										textAlign: "center",
									}}
								>
									<button
										style={{
											color: "red",
											border: "1px solid red",
											borderRadius:
												"var(--border-radius)",
											padding: "1em 3em",
											outline: "none",
											background: "white",
										}}
									>
										Internal Error !!!
									</button>
								</div>
							)}
						</ChatInputBox>
						{/* <div style={{ textAlign: "center" }}>
              <Link
                href="https://forms.gle/uVFCEetM6FkaBPmj6"
                style={{ color: "#ff6396" }}
              >
                help us to become better
              </Link>
            </div> */}
					</ParentBox>
				)}
			</GoogleOAuthProvider>
		</>
	);
}

export default LandingPage;
