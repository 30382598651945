import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FeedbackIcon from "@mui/icons-material/Feedback";
import MenuIcon from "@mui/icons-material/Menu";
import { prompts } from "../util/prompts";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import Logo from "../images/logo1.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Twitter from "@mui/icons-material/Twitter";

const MenuTitle = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`;
const TemporaryDrawer = React.forwardRef(({ sendMessage,loading }, ref) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  React.useImperativeHandle(ref, () => ({
    log() {
      setState({ ...state, ["left"]: true });
    },
  }));

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        background: "var(--primary-clr)",
        color: "#ffff",

        /* Set the width and height of the scrollbar */
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
	  <ListItem key={"Socials"}>
          <ListItemButton
		  onClick={() => {
			window.location.href = "https://www.facebook.com/people/Love-GPT/100090563584193/";
		  }}>
            <FacebookIcon style={{ color: "white" }} />
          </ListItemButton>
          <ListItemButton
		  onClick={() => {
			window.location.href = "https://www.instagram.com/love_gpt/";
		  }}>
            <InstagramIcon style={{ color: "white" }} />
          </ListItemButton>
          <ListItemButton
		  onClick={() => {
			window.location.href = "https://twitter.com/devshreyan";
		  }}>
            <TwitterIcon style={{ color: "white" }} />
          </ListItemButton>
		  <ListItemButton
		  onClick={() => {
			window.location.href = "https://www.linkedin.com/in/devsm/";
		  }}>
            <LinkedInIcon style={{ color: "white" }} />
          </ListItemButton>
        </ListItem>
		<Divider sx={{ borderBottomWidth: 5, color: "white", margin: "0.5em 0 0.5em" }} />
        <ListItem key={"Feedback"} disablePadding>
          <ListItemButton
            onClick={() => {
              window.location.href = "https://forms.gle/uVFCEetM6FkaBPmj6";
            }}
          >
            <ListItemIcon>
              <FeedbackIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary={"Feedback"} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider sx={{ borderBottomWidth: 5, color: "white", margin: "0.5em 0 0.5em" }} />
      <Typography
        sx={{ mt: 0.5, ml: 2, color: "var(--secondary-clr)" }}
        display="block"
        variant="caption"
        className="title"
      >
        Write a:
      </Typography>
      <List>
        {prompts.slice(0, 3).map((el, index) => (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                if (!loading)
                sendMessage(el.title);
              }}
            >
              <ListItemText primary={el.title} className="message" />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider sx={{ borderBottomWidth: 5, color: "white", margin: "1em 0" }} />
      <Typography
        sx={{ mt: 0.5, ml: 2, color: "var(--secondary-clr)" }}
        display="block"
        variant="caption"
        className="title"
      >
        Ask out for a:
      </Typography>
      <List>
        {prompts.slice(3, 6).map((el, index) => (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                if (!loading)
                sendMessage(el.title);
              }}
            >
              <ListItemText primary={el.title} className="message" />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider sx={{ borderBottomWidth: 5, color: "white", margin: "1em 0" }} />
      <Typography
        sx={{ mt: 0.5, ml: 2, color: "var(--secondary-clr)" }}
        display="block"
        variant="caption"
        className="title"
      >
        Alternate follow up topics you can try:
      </Typography>

      <List>
        {prompts.slice(6, prompts.length).map((el, index) => (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                if (!loading)
                sendMessage(el.title);
              }}
            >
              <ListItemText primary={el.title} className="message" />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div
      style={{
        position: "sticky",
        top: "0",
        left: "0",
        zIndex: "1000",
      }}
    >
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 var(--padding)",
              background: "var(--primary-clr)",
              boxShadow: "var(--box-shadow)",
            }}
          >
            <Button
              onClick={toggleDrawer(anchor, true)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1em",
              }}
            >
              <MenuTitle style={{ color: "white" }}>Suggestions</MenuTitle>
              <MenuIcon sx={{ color: "white", transform: "scale(1.5)" }} />
            </Button>
            <Typography
              sx={{ padding: "10px" }}
              color="#FF597B"
              display="block"
              variant="h1"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                }}
              >
                {" "}
                <span style={{ fontSize: "1.75rem" }}>
                  LoveGPT
                  <sub style={{ fontSize: "10px" }}>BETA</sub>{" "}
                </span>
                <span>
                  <img src={Logo} width="50" alt="loveGPT" />
                </span>
              </div>
            </Typography>
          </div>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
});

export default TemporaryDrawer;
