import React from "react";
import styled from "@emotion/styled";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import Chip from "@mui/material/Chip";
import { Typography } from "@mui/material";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Logo from "../images/logo1.png";

import { createCompletion } from "../services/openai.service";
const ChatMessageParent = styled.div`
  display: flex;
  align-items: center;
`;
const CopyToClipBoard = styled(Chip)`
  height: fit-content;
  width: fit-content;
  padding: 0.2rem;
  padding-top: 0.4em;
  background: var(--ternary-clr);
  color: white;
`;
const Chat = styled.div`
  padding: calc(var(--padding) / 2) var(--padding);
  display: block;
  position: relative;
  width: 100%;
  position: relative;
`;
const Messagenger = styled.div`
  font-weight: bold;
  /* position: absolute;
  top: -20px;
  left: 0; */
`;
function ChatMessage({
  sendMessage,
  example,
  setLoading,
  setChatArr,
  chatArr,
  direction,
  message,
  name,
  activeMessageTobeSent,
}) {
  return (
    <ChatMessageParent style={{ justifyContent: direction }}>
      {direction === "flex-end" ? (
        <Chat
          style={{
            background: "var(--secondary-clr)",
            textAlign: "right",
            animation: "user-message 0.5s ease forwards",
          }}
        >
          <Messagenger
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              gap: "1em",
              padding: "var(--message-padding)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-end",
                gap: "1em",
              }}
            >
              <p className="title">{name}</p>
              <Typography>
                {message.split("\n").map((str) => (
                  <p className="message">{str}</p>
                ))}
              </Typography>
            </div>
            {/* <AccountCircleIcon
							style={{ transform: "scale(1.5)" }}
						/> */}
          </Messagenger>
        </Chat>
      ) : (
        <Chat>
          <Messagenger
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              height: "fit-content",
              gap: "1em",
              animation: "bot-message 0.5s ease forwards",
              padding: "var(--message-padding)",
            }}
          >
            <img
              src={Logo}
              alt="chat-logo"
              style={{ width: "3em", margin: ".2em 0" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "1em",
                marginTop: ".75em",
                width: "100%",
              }}
            >
              <p className="title">{name}</p>
              <Typography>
                {message.split("\n").map((str) => (
                  <p className="message">{str}</p>
                ))}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-end",
                gap: "1em",
                width: "min-content",
              }}
            >
              <CopyToClipBoard
                label={<ContentCopyIcon />}
                title={"Copy text"}
                onClick={() => {
                  navigator.clipboard.writeText(message);
                }}
                sx={{
                  "&:hover": {
                    background: "var(--primary-clr)",
                  },
                }}
              />
              {!example && (
                <CopyToClipBoard
                  sx={{
                    "&:hover": {
                      background: "var(--primary-clr)",
                    },
                  }}
                  label={<RefreshIcon />}
                  title={"Retry for different result"}
                  onClick={async () => {
                    try {
                      sendMessage(
                        activeMessageTobeSent?.title,
                        activeMessageTobeSent?.isFromChat
                      );
                    } catch (e) {
                      console.log("ERROR CHAT MESSAGE");
                      console.log(e);
                    }
                  }}
                />
              )}
            </div>
          </Messagenger>
        </Chat>
      )}
    </ChatMessageParent>
  );
}

export default ChatMessage;
