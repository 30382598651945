export const prompts = [
  {
    title: "Love letter 💌",
  },
  {
    title: "Proposal letter 💘",
  },
  {
    title: "Pickup line ❤️",
  },
  {
    title: "Coffee ☕",
  },
  {
    title: "Movie 🍿",
  },
  {
    title: "Drive 🚗",
  },
  {
    title: "Flirty opening on photo 👩"
  },
  {
    title: "What made you Swipe Right on my profile?",
  },
  {
    title: "What does a day in the life of (name) look like?",
  },
  {
    title: "Do you have any pets? Or want to own one in the future?",
  },
  {
    title: "Do you love your work?",
  },
  {
    title: "What's your first impression of people?",
  },
  {
    title: "What does your typical weekend look like?",
  },
  {
    title: "Next Trip",
  },
  {
    title: "When did your last relationship end?",
  },
  {
    title: "Who’re you closer to? Your mom or dad?",
  },
  {
    title: "What are some items on your bucket list?",
  },
  {
    title: "What's your goal for the next 5 years?",
  },
  {
    title: "If you would choose to be a superhero, who would you be?",
  },
];
