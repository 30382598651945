export const getState = (key) => {
    try {
      const serializedState = localStorage.getItem(key)
      if (!serializedState) {
        return undefined
      }
      return key === 'numberOfTimesUsed' ? serializedState : JSON.parse(serializedState)
    } catch (err) {
      return undefined
    }
  }
  
  export const setState = (key, data) => {
    try {
      const serializedState = key === 'numberOfTimesUsed' ? data : JSON.stringify(data)
      localStorage.setItem(key, serializedState)
    } catch {
      // ignore write errors
    }
  }
  
  export const removeState = (key) => {
    try {
      localStorage.removeItem(key)
    } catch {
      // ignore write errors
    }
  }
  
  export const clearState = () => {
    localStorage.clear()
  }
  