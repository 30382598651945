import Axios from 'axios';
// import { CONFIG } from './../config/config.env';

export class ApiRequestService {
  constructor(baseURL) {
    this.baseURL = baseURL

    this.axiosService = Axios.create({
      baseURL: this.baseURL,
      // timeout: 60000,
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
    });
  }

  getApi(path, headers = {}, params = {}) {
    return new Promise((resolve) => {
      this.axiosService
        .get(path, {
          headers,
          params,
        })
        .then((res) => {
          resolve({
            message: res.message,
            status: !!res.status,
            statusText: 'Success',
            data: res.data,
          });
        })
        .catch((error) => {
          console.table(error.response);
          resolve({
            message: error?.response?.data?.message,
            status: false,
            statusText: 'Error',
          });
        })
        .then(() => {
          // here the code which should be excuted always
        });
    });
  }

  postApi(path, headers = {}, apiData) {
    return new Promise((resolve, reject) => {
      this.axiosService
        .post(path, apiData, {
          headers,
        })
        .then((res) => {
          console.log("POST API Response: ", res);
          resolve({
            message: res.data.message,
            status: true,
            data: res.data,
          });
        })
        .catch((error) => {
          console.log("POST API Error: ", error);
          reject({
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.response?.data,
            status: false,
            code: error?.response?.status,
          });
        })
        .then(() => {
          // here the code which should be excuted always
        });
    });
  }

  deleteApi(path, apiData) {
    return new Promise((resolve) => {
      this.axiosService
        .delete(path, apiData)
        .then((res) => {
          resolve({
            message: res.data.message,
            status: res.data.status,
            statusText: 'Success',
            data: res.data.data,
          });
        })
        .catch((error) => {
          resolve({
            message: error?.response?.data?.message,
            status: false,
            statusText: 'Error',
          });
        })
        .then(() => {
          // here the code which should be excuted always
        });
    });
  }

  putApi(path, apiData, headers = {}) {
    return new Promise((resolve) => {
      this.axiosService
        .put(path, apiData, {
          headers,
        })
        .then((res) => {
          resolve({
            message: res.data.message,
            status: res.data.status,
            statusText: 'Success',
            data: res.data.data,
          });
        })
        .catch((error) => {
          resolve({
            message: error?.response?.data?.message,
            status: false,
            statusText: 'Error',
          });
        })
        .then(() => {
          // here the code which should be excuted always
        });
    });
  }
}

export default ApiRequestService;
